import React, { useState } from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import { StringyWindup } from "../components/stringy-windup/stringy-windup";
import "./payment-service-page.scss";
import "./contacto.scss";
import Fade from "react-reveal/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileContract,
    faFile,
    faFileInvoice,
    faShoppingBasket,
    faHandsHelping,
    fa,
} from "@fortawesome/free-solid-svg-icons";
import ServicePaymentForm from "../components/service-payment-form/service-payment-form";
import { Link } from "gatsby";
import { sendEmailPaymentCompleted } from "../utils/mailService";
import { qDataToArray } from "../utils/helpers";
import { getUserQuestionnaire } from "../components/questionnaire-wizard/questionnaire-data-service";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { SloganFlipper } from "../components/slogan/slogan-flipper";

const handlePaymentCompleted = (response) => {
    const formData = {
        ...response.formData,
        serviceName: "Dictamen",
        servicePrice: 30,
    };

    // TODO recoger datos del localStorage questionnaireData
    const questionnaireData = getUserQuestionnaire();

    // TODO recoger datos del pago de paypal
    const paymentData = response.paymentData;
    // console.log(
    //     "JES onPaymentCompleted",
    //     formData,
    //     questionnaireData,
    //     paymentData
    // );

    sendEmailPaymentCompleted({
        formData,
        questions: qDataToArray(questionnaireData),
        paymentData,
    });
};

export default function EncargoDictamen() {
    const [status, setStatus] = useState("");
    const userQuestionnaire = getUserQuestionnaire();

    return (
        <React.Fragment>
            <SEO title="Encargo de dictamen" />
            <Layout pageId="order-dictamen">
                <div className="order-dictamen-container payment-service-page-container">
                    <h1>Encarga un dictamen profesional</h1>

                    <StringyWindup
                        text="Un dictamen es un documento que valora jurídicamente tu caso informándote de las opciones y la viabilidad a la hora de reclamar una negligencia de tu abogado"
                        callbackFn={() => setStatus("step-1")}
                    ></StringyWindup>

                    <Fade left duration={2000} when={status === "step-1"}>
                        <ul className="highlights">
                            <li>
                                <FontAwesomeIcon
                                    icon={faFileInvoice}
                                    size="4x"
                                    color="#94acc4"
                                />
                                <div>
                                    {!userQuestionnaire && (
                                        <React.Fragment>
                                            1. Si no lo has hecho todavía, es
                                            muy recomendable rellenar
                                            previamente nuestro{" "}
                                            <Link to="/cuestionario-autovaloracion/">
                                                cuestionario de autovaloración
                                            </Link>
                                        </React.Fragment>
                                    )}
                                    {userQuestionnaire && (
                                        <React.Fragment>
                                            1. Has rellenado el cuestionario de
                                            autovaloración!{" "}
                                            <strong>
                                                Este paso ya lo tienes!
                                            </strong>
                                            , la información proporcionada
                                            ayudará a valorar tu caso.
                                        </React.Fragment>
                                    )}
                                </div>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    icon={faFileContract}
                                    size="4x"
                                    color="#94acc4"
                                />
                                <div>
                                    2. Encarga el dictamen rellenando el
                                    siguiente{" "}
                                    <a href="#formulario-te-llamamos">
                                        formulario
                                    </a>
                                </div>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    icon={faHandsHelping}
                                    size="4x"
                                    color="#94acc4"
                                />
                                <div>
                                    3. Nuestro experto te contactará para
                                    recabar información y en pocos días puedes
                                    tener el dictamen en tu poder
                                </div>
                            </li>
                        </ul>
                        <Accordion allowZeroExpanded={true}>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Más detalles
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        En el encargo del dictamen se incluye{" "}
                                        <strong>
                                            asesoramiento telefónico
                                        </strong>{" "}
                                        cuyas condiciones puedes consultar{" "}
                                        <Link to="/consulta-experto/#condiciones">
                                            {" "}
                                            aquí{" "}
                                        </Link>
                                    </p>

                                    <h2>
                                        ¿Cuanto tardará el experto en tener
                                        listo el documento?
                                    </h2>
                                    <p>
                                        Nuestros expertos se comprometen a tener
                                        listo el dictámen en el{" "}
                                        <strong>plazo máximo de 3 días</strong>{" "}
                                        desde que le envíes la documentación que
                                        te pidan. Para ello es muy importante
                                        tener toda la documentación de tu caso,
                                        si no la tienes consulta{" "}
                                        <Link to="/como-conseguir-documentacion-necesaria/">
                                            este enlace
                                        </Link>
                                        .
                                    </p>
                                    <p>
                                        De todas formas el experto te indicará
                                        cuando te contacte qué documentación
                                        concreta es la necesaria para el
                                        dictamen y la forma en que se la tienes
                                        que mandar y a continuación{" "}
                                        <strong>
                                            te enviará un correo electrónico
                                            recordándote todo lo hablado para
                                            que no se olvide nada
                                        </strong>{" "}
                                        y no haya dudas.{" "}
                                    </p>
                                    <h2>
                                        ¿Cómo se enviará el documento
                                        resultante?
                                    </h2>
                                    <p>
                                        El experto te enviará un{" "}
                                        <strong>correo electrónico</strong> con
                                        el dictamen en el que podrás
                                        previsualizar la primera página del
                                        mismo y las instrucciones para poder
                                        obtener el documento completo. Pinchando
                                        aquí puedes ver{" "}
                                        <Link to="/modelos-dictamen/">
                                            el modelo de dictamen tipo que te
                                            llegará
                                        </Link>
                                    </p>
                                    <h2>
                                        ¿Para qué me sirve tener un dictamen?
                                    </h2>
                                    <p>
                                        El dictamen es un{" "}
                                        <strong>
                                            documento con valor jurídico
                                        </strong>{" "}
                                        y que te ahorrará mucho tiempo/dinero a
                                        la hora de reclamar a tu abogado, sin
                                        necesidad de acudir a otras vías para asesorarte inicialmente. Más
                                        información{" "}
                                        <Link to="/dictamen-documento-valioso-para-reclamar/">
                                            aquí
                                        </Link>
                                    </p>
                                    <h2>¿Cuánto vale el dictamen?</h2>
                                    <p>
                                        El precio total del dictamen es de{" "}
                                        <strong>170 €</strong> (IVA no incluido)
                                        en los que va{" "}
                                        <strong>
                                            incluida la consulta
                                        </strong>
                                        , la cual hay que{" "}
                                        <strong>pagar por adelantado</strong>{" "}
                                        para que nuestros expertos se pongan a
                                        trabajar. Una vez esté listo el dictamen{" "}
                                        <strong>solo pagarás 140 €</strong> (IVA
                                        no incluido) pues viene descontado ya el
                                        importe de la consulta.
                                    </p>
                                    <h2>
                                        ¿Puedo comprar la consulta y más
                                        adelante encargar el dictamen
                                        aprovechando el descuento?
                                    </h2>
                                    <p>
                                        Sin problema!! Siempre que lo hagas{" "}
                                        <strong>
                                            dentro de los tres primeros meses
                                        </strong>{" "}
                                        en que hiciste la consulta, para ello
                                        nuestro experto te explicará la forma de
                                        aprovechar el descuento en el correo
                                        electrónico que te mande después de la
                                        llamada, resumiendo el contenido de lo
                                        hablado.{" "}
                                    </p>
                                    <p>
                                        Pasados esos tres meses se pierde el
                                        descuento.
                                    </p>
                                    <h2>
                                        Rellenar previamente el cuestionario de
                                        autovaloración
                                    </h2>
                                    <p>
                                        Con objeto de{" "}
                                        <strong>
                                            aprovechar al máximo la conversación
                                            con el experto
                                        </strong>
                                        , es recomendable que rellene el{" "}
                                        <strong>
                                            <Link to="/cuestionario-autovaloracion/">
                                                cuestionario de autovaloración
                                            </Link>{" "}
                                        </strong>
                                        de forma que el profesional disponga del
                                        mayor volumen de datos para valorar su
                                        caso.
                                    </p>
                                    <h2>
                                        Certificado de seguridad en los pagos
                                    </h2>
                                    <p>
                                        {" "}
                                        Utilizamos{" "}
                                        <a
                                            href="https://www.paypal.com/es/webapps/mpp/paypal-safety-and-security"
                                            target="_blank"
                                        >
                                            <strong>Paypal</strong>
                                        </a>{" "}
                                        como plataforma de pago que te
                                        proporciona una completa seguridad en la
                                        realización de pagos.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </Fade>
                    <Fade right duration={3000} when={status === "step-1"}>
                        <a name="formulario-te-llamamos"></a>
                        <ServicePaymentForm
                            title="Encargo de Dictamen"
                            amount={30}
                            onPaymentCompleted={handlePaymentCompleted}
                        />
                    </Fade>
                </div>
                <SloganFlipper></SloganFlipper>
            </Layout>
        </React.Fragment>
    );
}
