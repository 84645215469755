import React from "react";

import { useWindupString } from "windups";

export const StringyWindup = ({ text, callbackFn }) => {
    const [text2] = useWindupString(text, {
        onFinished: () => {
            callbackFn && callbackFn();
        },
    });
    return <p style={{fontSize: '1.2rem'}}>{text2}</p>;
};
